<template lang="pug">
.bulletinManager
    v-row
        v-col.d-flex
            span.text-h5.brand--text Bulletin Manager
            v-btn.ml-2(outlined, rounded, color='brand', @click='openNewBulletinDialog') Create Bulletin
                v-icon(right) add
    v-row
        v-col
            v-data-table(:items='bulletins', :headers='headers', @click:row='editBulletin')
    v-dialog(v-model='newBulletinDialog', max-width='300')
        v-card(light)
            v-card-title New Bulletin
            v-card-text
                v-row
                    v-col
                        v-select(dense, outlined, color='brand', :items='bulletinTypes', item-text='text', item-value='enum', v-model='bulletinTemplate.type')
                v-row
                    v-col
                        span The data below will not be displayed to customers.
                v-row
                    v-col
                        v-text-field(label='Name', outlined, dense, color='brand', v-model='bulletinTemplate.name')
                v-row
                    v-col
                        v-textarea(label='Description', outlined, dense, color='brand', v-model='bulletinTemplate.description')
            v-card-actions
                v-spacer
                v-btn(@click='newBulletinDialog = false', text, :loading='saving') Cancel
                v-btn.white--text(color='brand', @click='addNewBulletin', :loading='saving') Create
</template>
<script>
export default {
    meta: {
        role: 'devopscomms'
    },
    data () {
        return {
            accessKey: 'devopscomms',
            newBulletinDialog: false,
            saving: false,
            bulletins: [],
            bulletinTemplate: {
                name: '',
                description: '',
                type: 'general'
            },
            headers: [
                { text: 'Name', value: 'name', filterable: true},
                { text: 'Description', value: 'description', filterable: true, sortable: true},
                { text: 'Type', value: 'type', filterable: true },
                { text: 'Title', value: 'title', filterable: false },
                { text: '', value: 'edit'}
            ],
            bulletinTypes: [
                {enum: 'general', text: 'General'},
                {enum: 'security', text: 'Security'},
            ]
        };
    },
    methods: {
        /**
         * Gets the list of bulletins from the server.
         */
        async getBulletins () {
            const bulletins = await this.sendCommand({
                action: 'getBulletins'
            });
            this.bulletins = bulletins;
        },
        /**
         * Creates a new bulletin and navigates to the edit page for it.
         */
        async addNewBulletin () {
            this.saving = true;
            const newBulletin = await this.sendCommand({
                action: 'createBulletin',
                parameters: this.bulletinTemplate
            });
            this.saving = false;
            this.editBulletin(newBulletin);
        },
        /**
         * Displays the dialog to create a new bulletin.
         */
        openNewBulletinDialog () {
            this.newBulletinDialog = true;
        },
        editBulletin (bulletin) {
            this.$router.push({path: '/devops/editBulletin', query: {id: bulletin.id}});
        }
    },
    watch: {
        newBulletinDialog (visible) {
            if (!visible) {
                this.bulletinTemplate.name = '';
                this.bulletinTemplate.description = '';
            }
        }
    },
    mounted () {
        this.getBulletins();
    }
};
</script>
